<script setup>
import { useBanners } from "~/composables/useBanners";

const { getBannersByCategory } = useBanners();
const { sheets } = useApp();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  categoryModel: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(["update:modelValue", "update:categoryModel", "select"]);

const dialog = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit("update:modelValue", val);
  },
});

const bannersColl = computed(() => getBannersByCategory(props.categoryModel._id));

function close() {
  dialog.value = false;
}

onMounted(() => {
  // watchProps();
});
</script>
<template>
  <v-dialog
    v-model="dialog"
    bottom-sheet
    :options="{ maxWidth: 'var(--screen-max-width)', height: '100vh' }"
  >
    <div class="dialog-header">
      <v-btn class="close-btn" @click="close()">
        <v-svg
          src="/icons/arrow-sm-left-svgrepo-com.svg"
          :options="{ width: '30px' }"
        ></v-svg>
      </v-btn>
      <div class="dialog-title">
        {{ transl(props.categoryModel.label) }}
      </div>
    </div>
    <div class="dialog-content">
      <div class="dialog-body">
        <div class="banner-row mt-5">
          <div
            class="banner-card"
            v-for="banner in bannersColl"
            @click="emit('select', banner)"
          >
            <div class="image">
              <v-img :src="resolveImage(banner?.banner_src)" loader="true"></v-img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.dialog-content {
  padding: 0 20px;
  background-color: #fff;
}

.dialog-body {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

.dialog-header {
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.dialog-title {
  display: flex;
  justify-content: center;
  padding: 20px;
  color: #000;
  font-weight: bold;
  font-size: 21px;
}

.banner-row {
  display: grid;
  gap: 20px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 100px;
}

.banner-card {
  cursor: pointer;
  text-align: center;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  border-radius: 8px;
}
.banner-card .image {
  /* aspect-ratio: 1; */
  border-radius: 8px;
  overflow: hidden;
}
.banner-card img {
  aspect-ratio: 1;
  object-fit: cover;
  height: 100%;
  max-height: 280px;
}

.banner-card img {
  transition: scale 0.6s;
}
.banner-card:hover img {
  scale: 1.05;
}
.label {
  margin-top: 10px;
  font-size: 13px;
  min-height: 15px;
}
.nav-title {
  color: #000;
  font-weight: bold;
  font-size: 21px;
}
/* .banner-row {
  aspect-ratio: 1 / 1;
} */

.v-img {
  width: 100%;
  height: 100%;
  min-height: 130px;
}
/* .banner-card {
  width: 100%;
  height: 100%;
}  */
@media (max-width: 480px) {
  .banner-row {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
